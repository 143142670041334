import React, { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import moment from "moment";
import {
    Alert,
    Button,
    CheckBox,
    CustomDropdown,
    Dropdown,
    Icon,
    Input,
    Password,
    RadioBox,
    Spacer,
    TagInput,
    Text,
    Theme,
    ThirdTitle,
} from "@dspworkplace/ui";
import { useAuth, getFriendlyName, getRole, getAllowsDispatcherPermission } from "../../../Auth";
import { showFlexibleShiftBlockForm } from "../Week/components/shiftBlockForms";
import { confirm } from "../../../components/Confirm";
import { dialog, dialogPromise, loadingDialog } from "../../../components/Dialog";
import { showDriverSelection } from "../../SchedulerLoadOut/driverSelection";
import {
    DriverDeviceController,
    DriverRouteController,
    DriverVehicleController,
    MileageController,
} from "../../SchedulerLoadOut";
import { Table, TableData, TableHeader, TableRow } from "../../../components/Table";
import useOutsideClick from "../../../hooks/useOutsideClick";
import useSchedulerApi from "./api";
import toast from "../../../components/Toasts/toast";
import { useForm } from "react-hook-form";
import { Label, Link } from "../../../components/UI";
import { Action } from "./UI";
import { calculateDuration, covertTimeInNumber, databaseToMilliseconds } from "../../../components/TimeFormat";
import Driver from "../../../components/People";
import { openReportIncidentForm } from "../../Incidents/Common/ReportIncidentForm";
import { patternPassword,validatePassword } from "../../../patterns";
import Modal from "../../../components/Modal";
import Loading from "../../../components/Loading";
import SimpleScroll from "../../../components/SimpleScroll";
import API from "../../SchedulerLoadOut/api";
import { fetchPopupTimelineData } from "../../../api/utils";
import { RowTimeline } from "../../Employees/timeLine";
import { incidentPromise } from "../../Incidents/form";
import { useHistory } from "react-router-dom";
import { canModifyFuture, canModifyPast } from "./AccessCheck";
import {
    asDatabaseTime,
    stringToMilliseconds
} from "../../../components/TimeFormat";
import DatePicker from "../../../components/Calendar/picker";
import { alert } from "../../../components/Alert";

const ShiftPopupStyle = styled.div`
    > .header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .header-title {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .circle {
                box-sizing: border-box;
                display: block;
                width: 24px;
                height: 24px;
                border-radius: 100%;
                background: #00bda5;
                margin-top: -2px;
                border: 2px solid ${Theme.colors.neutrals.white};
                flex-shrink: 0;
            }

            .title {
                font-size: 16px;
                color: #516f90;
                font-family: circe-rounded;
                font-weight: 400;
            }
        }
    }
`;
const DateContent = styled.span`
    font-size: ${Theme.font.extraSmall.size};
    line-height: ${Theme.font.extraSmall.lineHeight};
    font-family: ${Theme.font.main};
    font-weight: bold;
    color: ${Theme.colors.neutrals.medium};
`;
const RestoreBlockStyle = styled.div`
    padding: 8px 12px;
    font-size: 14px;
    display: flex;
    gap: 8px;
    align-items: center;
    color: #DBAE60;
    background: #FEF8F0;
    border-radius: 2px;
    margin-bottom: 12px;
    border: 1px solid #F5C26B;
`;

const DropdownWrapper = styled.span`
    > div > .just-options > ul {
        right: 0;
        left: unset;
    }
`;

const ShiftBlockPopup = ({ shift, open, actions, schedule, incidents, viewType }) => {
    actions = {
        onEditShift: () => { },
        onEditShiftNote: () => { },
        onMoveToOpenRoute: () => { },
        onAssignToAnotherDriver: () => { },
        onDropRoute: () => { },
        onAddRescue: () => { },
        onEditRescue: () => { },
        onAddTrainer: () => { },
        onAddLightDuty: () => { },
        onRouteChange: () => { },
        onVehicleChange: () => { },
        onDeviceChange: () => { },
        onSendHome: () => { },
        onUnSendHome: () => { },
        onIncidentShift: () => { },
        onAddRefuseRescue: () => { },
        onEditNormalShift: () => { },
        ...actions,
    };
    const [, setTodayRouteCodes] = useState([]);
    const [extraData, setExtraData] = useState({ vehicle: {}, devices: [] });
    const [canMakeRescue, setCanMakeRescue] = useState(null);
    const [overtimeWarning, setOvertimeWarning] = useState(false);
    const { api } = useAuth();
    const { setOpen } = open;

    const {
        getShiftProperties,
        tempEditShift,
        archiveShift,
        getShiftOptions,
        saveRescueShift,
        saveRefusedRescueShift,
        saveSecondShift,
        getTodayRouteCodes,
        saveSpecialShift,
        setSendHome,
        removeRequest,
        getShiftData,
        dropLoadoutShift,
        restoreShift,
        recallShift,
        dropRescueLoadoutShift,
        createOpenRoute,
        createOpenRouteTemp,
        getShiftActions,
        getTraineeData,
        editShift,
        editDeletedShift,
        editDeletedShiftNote,
        getShiftMakeRescueCheck,
    } = useSchedulerApi();

    //TODO this block is rendering 5 at 7 times, need to render only 3.
    const past = canModifyPast(shift.stationId);
    const future = canModifyFuture(shift.stationId);

    const canModify = useMemo(() => {
        const date = moment(shift.shiftDate, "YYYY-MM-DD").local(true);

        const isToday = date.isSame(new Date(), "day");
        const isPast = date.isBefore(new Date(), "day");
        const isFuture = !isToday && !isPast;

        return (isToday || (isPast && past) || (isFuture && future));
    }, [past, future, shift]);

    const canDeleteModify = useMemo(() => {
        return shift.deleted != true;
    }, [shift]);

    const unAvailableShiftType = useMemo(() => {
        return shift.type === 'UNAVAILABLE' ? false : true;
    });

    const prepareOptions = async (overwrites = {}) => {
        const hide = loadingDialog();
        const response = await getShiftOptions({
            shift: {
                ...shift,
                ...overwrites,
            },
        });
        hide();

        if (!response) {
            toast({
                type: "warning",
                title: "No shift type found!",
            });
            return;
        }

        const shiftInvoiceTypes = response.data.data.invoiceTypes;
        const rescueShift = response.data.data.shift.find((s) => s.category === 4);
        const trainerShift = response.data.data.shift.find((s) => s.category === 5);
        const lightDutyShift = response.data.data.shift.find((s) => s.category === 6);

        return {
            ...response.data.data,
            shiftInvoiceTypes,
            shiftTypes: response.data.data.shift,
            rescueShift,
            trainerShift,
            lightDutyShift,
            loaded: true,
        };
    };

    const prepareRoutes = () => {
        return getTodayRouteCodes({
            station: shift.stationId,
            dateCreated: shift.shiftDate,
            routeId: shift.routeId,
            isRescue: true,
        }).then(
            (response) => {
                if (!response) return false;

                let codes = response.data.data.codes.map((c) =>
                    c.selected == false
                        ? {
                            name: c.code,
                        }
                        : {
                            name: c.code,
                            value: c.code,
                            // selected: shift.routeCode.includes(c.code)
                        }
                );
                setTodayRouteCodes(codes);
                return codes;
            },
            (error) => {
                if (error.response)
                    toast({
                        type: "error",
                        title: "Error",
                        content: error.response.data.message,
                    });
            }
        );
    };

    const handleEdit = async () => {
        // check other station shift
        if(shift.driverId != "0.0.0") {
            const params = {
                station: shift.stationId,
                checkIncident: true,
                driver: [shift.driverId],
                date: shift.dateCreated,
            };
            let res = await API.checkDriverRoute(api, params);
            if(res.isTodayPast === true) {
                let dialogMsg ="Driver " + res.availableDriverIds[0].name + " already have a scheduled shift in " + res.availableDriverIds[0].code +". \n You can not edit today or past shift..!";
                await alert({
                    text: dialogMsg,
                    btnText: "Cancel",
                });
                return false;
            }
        }
        
        let opts = await prepareOptions();
        let edited = await showFlexibleShiftBlockForm({
            shift: shift,
            options: opts,
            timeZoneShortName: shift.timezone,
        });
        if (!edited) return;

        if (
            shift.isNew == false &&
            shift.typeId == parseInt(edited.shiftType) &&
            (((shift.invoiceTypeId == undefined || shift.invoiceTypeId == "") === true && true === (edited.shiftInvoiceType == undefined || edited.shiftInvoiceType == "")) ||
                parseInt(shift.invoiceTypeId) == parseInt(edited.shiftInvoiceType)) &&
            parseFloat(shift.utcStartTime) == parseFloat(edited.utcStartTime) &&
            parseFloat(shift.utcEndTime) == parseFloat(edited.utcEndTime) &&
            parseFloat(shift.routeStatus) == parseFloat(edited.routeStatus) &&
            shift.onCall == edited.onCall && shift.backupStatus == edited.backupStatus &&
            (edited.autoApproveDriverRequest == undefined || shift?.autoApproveDriverRequest == edited?.autoApproveDriverRequest)
        ) {
            /*tempEditShiftNote({
                id: shift.id
            }).then(
                response => response && actions.onEditShiftNote(
                    shift,
                    {
                        ...shift,
                        currentNote: edited.note,
                        newNote: edited.newNote,
                        note: edited.note + ' ' + edited.newNote
                    }
                ),
                error => {
                    if (error.response)
                        toast({
                            type: 'error',
                            title: 'Error',
                            content: error.response.data.message
                        })
                }
            );*/
        } else {
            if (shift.driverId != "0.0.0") {
                const driverSkillParam = {
                    actions: {
                        response: {
                            Driver: {
                                custom: {
                                    functionName: "checkDriverAndShiftSkillMatch",
                                    get: "result",
                                    excludes: [],
                                    criteria: {
                                        driverId: shift.driverId,
                                        shiftId: edited.shiftType,
                                    },
                                },
                            },
                        },
                    },
                };
                const responseDriverSkill = await api.post("/api/lazy/manage/data", driverSkillParam);
                if (!responseDriverSkill.data.data.result.result) {
                    if (getAllowsDispatcherPermission() == "false" && "ROLE_DISPATCHER" == getRole()) {
                        alert({
                            text: "This driver does not possess the necessary Skill to be assigned this Shift Type.",
                            btnText: "Cancel",
                        });
                        return false;
                    } else {
                        const confirmation = await confirm(
                            'WARNING: "' +
                            responseDriverSkill.data.data.result.driverName +
                            '" does not have the required "' +
                            responseDriverSkill.data.data.result.shiftSkillName +
                            '" skill to be assigned this shift type. Are you sure you want to continue?'
                        );
                        if (!confirmation) {
                            return true;
                        }
                    }
                }
            }
            tempEditShift({
                id: shift.id,
                newRecord: {
                    ...shift,
                    ...edited,
                    shiftId: edited.shiftType,
                    shiftType: edited.extra,
                },
            }).then(
                (response) => response && actions.onEditShift(shift, response.data.data.tempDriverRoute),
                (error) => {
                    if (error.response)
                        toast({
                            type: "error",
                            title: "Error",
                            content: error.response.data.message,
                        });
                }
            );
        }
    };
    const handleDeletedShiftEdit = async () => {
        // check other station shift
        if (shift.driverId != "0.0.0") {
            const params = {
                station: shift.stationId,
                checkIncident: true,
                driver: [shift.driverId],
                date: shift.dateCreated,
            };
            let res = await API.checkDriverRoute(api, params);
            if(res.isTodayPast === true) {
                let dialogMsg ="Driver " + res.availableDriverIds[0].name + " already have a scheduled shift in " + res.availableDriverIds[0].code +". \n You can not edit today or past shift..!";
                await alert({
                    text: dialogMsg,
                    btnText: "Cancel",
                });
                return false;
            }
        }
        let opts = await prepareOptions();
        let edited = await showFlexibleShiftBlockForm({
            shift: shift,
            options: opts,
            timeZoneShortName: shift.timezone,
        });
        if (!edited) return;

        editDeletedShift({
            id: shift.routeId, updateRecord: {
                "shiftInvoiceType": edited.shiftInvoiceType,
                "shiftType": edited.shiftType,
                "endTime": edited.utcEndTime,
                "startTime": edited.utcStartTime
            }
        }).then(
            (resolve) => {
                actions.onEditShiftNote(shift, {
                    ...shift,
                    ...resolve.data.data.driverRoute,
                    deleted: shift.deleted,
                    deletedAt: shift.deletedAt,
                    deletedBy: shift.deletedBy,
                    isNew: shift.isNew,
                    hasAlert: shift.hasAlert,
                });
            },
            (error) => {
                //alert({})
            }
        );

    };

    const handleDrop = async () => {
        let confirmation = await confirm({
            icon: true,
            title: "Confirm drop shift",
            text: `You are about to drop this shift. Please confirm your action.`,
        });

        if (!confirmation) return;

        archiveShift({
            id: shift.routeId,
            shift,
        }).then(
            (response) => response && actions.onDropRoute(shift, response.data.data.result),
            (error) => {
                if (error.response)
                    toast({
                        type: "error",
                        title: "Error",
                        content: error.response.data.message,
                    });
            }
        );
    };

    const handleDelete = async () => {
        let buttons = [
            {
                inline: false,
                text: "Cancel",
                type: "cancel",
                callback: () => false,
            },
        ];

        if (shift.driverId !== "0.0.0") {
            buttons.push({
                text: "Move to Open Routes",
                inline: false,
                style: {
                    width: "100%",
                    marginTop: 8,
                },
                callback: async () => {
                    tempEditShift({
                        id: shift.id,
                        newRecord: {
                            ...shift,
                            driverId: "0.0.0",
                        },
                        oldRecord: shift,
                    }).then(
                        (response) => response && actions.onMoveToOpenRoute(shift, response.data.data.tempDriverRoute),
                        (error) => {
                            if (error.response)
                                toast({
                                    type: "error",
                                    title: "Error",
                                    content: error.response.data.message,
                                });
                        }
                    );
                },
            });
        }

        buttons.push({
            text: "Reassign to another driver",
            inline: false,
            style: {
                width: "100%",
                marginTop: 8,
            },
            callback: async () => {
                let driver = await showDriverSelection(
                    {
                        children: <Text>Reassign route {shift.routeCode}</Text>,
                    },
                    api,
                    {
                        type: "REASIGN_DRIVER",
                        station: shift.stationId,
                        startTime: shift.utcStartTime,
                        endTime: shift.utcEndTime,
                        routeId: shift.routeId,
                        shiftDate: shift.shiftDate,
                        driverId: shift.driverId,
                    }
                );

                if (!driver) return;

                tempEditShift({
                    id: shift.id,
                    newRecord: {
                        ...shift,
                        driverId: driver.id,
                    },
                    oldRecord: shift,
                }).then(
                    (response) =>
                        response && actions.onAssignToAnotherDriver(driver, shift, response.data.data.tempDriverRoute),
                    (error) => {
                        if (error.response)
                            toast({
                                type: "error",
                                title: "Error",
                                content: error.response.data.message,
                            });
                    }
                );
            },
        });

        buttons.push({
            text: "Drop Route",
            inline: false,
            style: {
                width: "100%",
                marginTop: 8,
            },
            callback: async () => {
                const c = await confirm({
                    icon: true,
                    title: "Confirm drop route",
                    text: `You are about to drop <u>${shift.routeCode.join(", ")}</u>. Please confirm your action.`,
                });

                if (!c) return false;

                archiveShift({
                    id: shift.routeId,
                    shift,
                }).then(
                    (response) => response && actions.onDropRoute(shift, response.data.data.result),
                    (error) => {
                        if (error.response)
                            toast({
                                type: "error",
                                title: "Error",
                                content: error.response.data.message,
                            });
                    }
                );
            },
        });

        dialog({
            icon: <Icon.Warning size="60px" color={Theme.colors.warnings.text} />,
            title: "Confirm delete shift",
            text: `
                You are deleting this shift.
                ${shift.routeCode.length
                    ? `This will unassign <u>${shift.routeCode.join(", ")}</u>. What should happen?`
                    : ""
                }
            `,
            buttons: buttons,
        });
    };

    const handleAddRescue = async () => {
        let [opts, todayCodes] = await Promise.all([prepareOptions({ isRescuer: true }), prepareRoutes()]);

        const { rescueShift, invoiceTypes } = opts;

        if (!rescueShift)
            return toast({
                type: "warning",
                title: "No shift type found!",
            });
        showFlexibleShiftBlockForm({
            options: {
                shiftTypes: [rescueShift],
                shiftInvoiceTypes: invoiceTypes,
                loaded: true,
            },
            title: "Add Rescue Shift",
            timeZoneShortName: shift.timezone,
            shift: {
                typeId: rescueShift.id,
                routeId: shift.routeId,
                category: shift.category,
                // startTime: parseFloat(
                //     moment()
                //         .add(1, "minutes")
                //         .format("H.m")
                // ),
                // utcStartTime: parseFloat(
                //     moment().utc()
                //         .add(1, "minutes")
                //         .format("H.m")
                // ),
                // endTimeOfPriorShift:parseFloat(
                //     moment().utc()
                //         .format("H.m")
                // ),
                // utcEndTimeOfPriorShift: parseFloat(
                //     moment().utc()
                //         .format("H.m")
                // ),
                endTime: shift.endTime,
                utcEndTime: shift.utcEndTime,
                utcPriorShiftStartTime: shift.utcStartTime,
                stationId: shift.stationId,
                isRescue: true,
                invoiceTypeId: 0,
                isRescuerEdit: false,
                isPunchedOut: shift.isPunchedOut,
                isPerformanceRelated: shift.isPerformanceRelated,
                shiftDate: shift.shiftDate,
            },
            fields: [
                {
                    component: TagInput,
                    props: {
                        name: "routeCode",
                        label: "Route Being Rescued",
                        Required: true,
                        options: todayCodes.filter((rc) => !shift.routeCode.includes(rc.name)),
                        singleOption: true,
                    },
                },
                {
                    component: Input,
                    props: {
                        name: "numberOfPackage",
                        label: "Number of Packages",
                        Required: true,
                    },
                },
            ],
        }).then((rescueShift) => {
            if (!rescueShift) return;

            saveRescueShift({
                rescueShift,
                rescued: shift,
            }).then(
                (response) => {
                    if (!response) return;

                    const rescuer = response.data.data.rescuer[1];
                    const oldShift = response.data.data.rescuer[0];
                    const rescued = response.data.data.rescuer[2];
                    /*const editedShift = {
                        ...shift,
                        endTime: rescuer.endTimeOfPriorShift,
                        utcEndTime: rescuer.endTimeOfPriorShift,
                        hour: `${TimeFormat({
                            time: {
                                timestamp: databaseToMilliseconds(shift.startTime.toFixed(2).replace('.', ':')) / 1000
                            }
                        })}-${covertUtcToLocal({
                            timestamp: databaseToMilliseconds(rescueShift.endTimeOfPriorShift) / 1000
                        })}`
                    }*/

                    actions.onAddRescue(oldShift, rescuer, rescued);
                },
                (error) => {
                    if (error.response)
                        toast({
                            type: "error",
                            title: "Error",
                            content: error.response.data.message,
                        });
                }
            );
        });
    };

    const handleAddSecondShift = async () => {
        let opts = await prepareOptions();

        const { rescueShift, invoiceTypes } = opts;

        let shiftObj = opts.shift; //.filter(item => item.id !== shift.typeId);

        showFlexibleShiftBlockForm({
            title: "Add Second Shift",
            timeZoneShortName: shift.timezone,
            options: {
                invoiceTypes: opts.invoiceTypes,
                shift: shiftObj,
                shiftInvoiceTypes: opts.shiftInvoiceTypes,
                shiftTypes: shiftObj,
                loaded: true,
            },
            shift: {
                typeId: 1,
                routeId: shift.routeId,
                stationId: shift.stationId,
                timeDiff: shift.shiftTimeDiffrence,
                isRescue: true,
                isSecondShift: true,
                invoiceTypeId: 0,
                isRescuerEdit: false,
                // startTime: parseFloat(
                //     moment()
                //         .add(1, "minutes")
                //         .format("H.m")
                // ),
                // utcStartTime: parseFloat(
                //     moment().utc()
                //         .add(1, "minutes")
                //         .format("H.m")
                // ),
                // endTimeOfPriorShift:parseFloat(
                //     moment().utc()
                //         .format("H.m")
                // ),
                // utcEndTimeOfPriorShift: parseFloat(
                //     moment().utc()
                //         .format("H.m")
                // ),
                endTime: shift.endTime,
                utcEndTime: shift.utcEndTime,
                utcPriorShiftStartTime: shift.utcStartTime,
                shiftDate: shift.shiftDate,
            },
        }).then((secondShift) => {
            if (!secondShift) return;

            saveSecondShift({
                secondShift,
                oldShift: shift,
            }).then(
                (response) => {
                    if (!response) return;

                    const secondShiftData = response.data.data.secondShift[1];
                    const editedShift = response.data.data.secondShift[0];
                    /* const editedShift = {
                        ...shift,
                        endTime: secondShiftData.endTimeOfPriorShift,
                        utcEndTime: secondShiftData.endTimeOfPriorShift,
                        isSecondShift: true,
                        hour: `${TimeFormat({
                            time: {
                                timestamp: databaseToMilliseconds(shift.startTime.toFixed(2).replace('.', ':')) / 1000
                            }
                        })}-${covertUtcToLocal({
                            timestamp: databaseToMilliseconds(secondShift.endTimeOfPriorShift) / 1000
                        })}`
                    }*/

                    actions.onAddRescue(editedShift, secondShiftData, []);
                },
                (error) => {
                    if (error.response)
                        toast({
                            type: "error",
                            title: "Error",
                            content: error.response.data.message,
                        });
                }
            );
        });
    };

    const handleEditRescue = async () => {
        const priorShift = await getShiftData({ id: shift.priorShiftRouteId });
        let opts = await prepareOptions({ isRescuer: true });

        const { shiftInvoiceTypes, rescueShift } = opts;

        if (!rescueShift)
            return toast({
                type: "warning",
                title: "No shift type found!",
            });
        showFlexibleShiftBlockForm({
            options: {
                shiftTypes: [rescueShift],
                shiftInvoiceTypes: shiftInvoiceTypes,
                loaded: true,
            },
            title: "Edit Rescue Shift",
            timeZoneShortName: shift.timezone,
            shift: {
                ...shift,
                typeId: rescueShift.id,
                isRescue: true,
                isRescuerEdit: shift.routeCode,
                utcPriorShiftStartTime: priorShift.data.data.driverRoute.utcStartTime,
                endTime: shift.endTime,
                utcEndTime: shift.utcEndTime,
            },
        }).then((edited) => {
            if (!edited) return;

            saveRescueShift({
                rescueShift: {
                    ...edited,
                    isEdit: true,
                    startTime: moment.utc(edited.startTime, "H.m").format("HH:mm"),
                    endTime: moment.utc(edited.endTime, "H.m").format("HH:mm"),
                    shiftNote: edited.note,
                },
                rescued: shift,
                isEdit: true,
            }).then(
                (response) => {
                    if (!response) return;

                    const rescuer = response.data.data.rescuer[1];
                    const oldShift = response.data.data.rescuer[0];
                    actions.onEditRescue(oldShift, rescuer);
                },
                (error) => {
                    if (error.response)
                        toast({
                            type: "error",
                            title: "Error",
                            content: error.response.data.message,
                        });
                }
            );
        });
    };

    const handleSecondShiftEdit = async () => {
        let opts = await prepareOptions();
        const priorShift = await getShiftData({ id: shift.priorShiftRouteId });

        const { shiftInvoiceTypes, rescueShift } = opts;

        showFlexibleShiftBlockForm({
            options: opts,
            title: "Edit Second Shift",
            timeZoneShortName: shift.timezone,
            shift: {
                ...shift,
                typeId: shift.typeId,
                routeId: shift.routeId,
                utcPriorShiftStartTime: priorShift.data.data.driverRoute.utcStartTime,
                endTime: shift.endTime,
                utcEndTime: shift.utcEndTime,
                stationId: shift.stationId,
                isRescue: true,
                invoiceTypeId: shift.invoiceTypeId,
                isRescuerEdit: false,
            },
        }).then((secondShift) => {
            if (!secondShift) return;

            saveSecondShift({
                secondShift: {
                    ...secondShift,
                    isEdit: true,
                    startTime: moment.utc(secondShift.startTime, "H.m").format("HH:mm"),
                    endTime: moment.utc(secondShift.endTime, "H.m").format("HH:mm"),
                    shiftNote: secondShift.note,
                },
                oldShift: shift,
                isEdit: true,
            }).then(
                (response) => {
                    if (!response) return;

                    const secondShift = response.data.data.secondShift[1];
                    const oldShift = response.data.data.secondShift[0];
                    actions.onEditRescue(oldShift, secondShift);
                },
                (error) => {
                    if (error.response)
                        toast({
                            type: "error",
                            title: "Error",
                            content: error.response.data.message,
                        });
                }
            );
        });
    };

    const handleEditTrain = async (isTrain) => {
        let opts = await prepareOptions({ isTrain: isTrain, isDuty: !isTrain, isEditTrainDutyShift: true });

        const { shiftInvoiceTypes, trainerShift, lightDutyShift, drivers, shiftTypes } = opts;
        if (!shiftTypes)
            return toast({
                type: "warning",
                title: "No shift type found!",
            });
        const shiftObj = shiftTypes;
        let fieldObj = {};
        if (isTrain) {
            const dataTrainees = await getTraineeData({ id: shift.id });
            const trainees = dataTrainees.data.data.trainees;
            let driversObj = [];
            let tempEditDriver = {};
            drivers.map((opt) => {
                if (trainees.includes(opt.id.toString())) {
                    tempEditDriver = {
                        value: opt.id,
                        name: opt.fullName,
                        profile: opt.user.image,
                        selected: true,
                    };
                } else {
                    tempEditDriver = {
                        value: opt.id,
                        name: opt.fullName,
                        profile: opt.user.image,
                    };
                }
                driversObj.push(tempEditDriver);
            });
            fieldObj = {
                options: {
                    shiftTypes: shiftObj,
                    shiftInvoiceTypes: shiftInvoiceTypes,
                    loaded: true,
                },
                timeZoneShortName: shift.timezone,
                title: isTrain ? "Edit Train Shift" : "Edit Light Duty Shift",
                shift: {
                    ...shift,
                    typeId: shift.typeId,
                    isTrain: isTrain ? true : false,
                    isDuty: isTrain ? false : true,
                },
                fields: [
                    {
                        component: CustomDropdown,
                        props: {
                            name: "trainees",
                            label: "Trainees",
                            Required: true,
                            type: "people",
                            multiple: true,
                            options: driversObj,
                            visibleOptionsQty: 6,
                        },
                    },
                ],
            };
        } else {
            fieldObj = {
                options: {
                    shiftTypes: shiftObj,
                    shiftInvoiceTypes: shiftInvoiceTypes,
                    loaded: true,
                },
                title: isTrain ? "Edit Train Shift" : "Edit Light Duty Shift",
                timeZoneShortName: shift.timezone,
                shift: {
                    ...shift,
                    typeId: shift.typeId,
                    isTrain: isTrain ? true : false,
                    isDuty: isTrain ? false : true,
                },
            };
        }
        showFlexibleShiftBlockForm(fieldObj).then(async (newShift) => {
            if (!newShift) return;
            if ([6, 5].includes(newShift.extra.category)) {
                //Edit train and light duty
                saveSpecialShift({
                    newShift: {
                        ...newShift,
                        isTrain: isTrain ? true : false,
                        isDuty: isTrain ? false : true,
                        startTime: moment.utc(newShift.startTime, "H.m").format("HH:mm"),
                        endTime: moment.utc(newShift.endTime, "H.m").format("HH:mm"),
                    },
                    related: shift,
                    isEdit: true,
                }).then((response) => {
                    if (!response) return;

                    const result = response.data.data.result;

                    actions.onAddTrainer(
                        {
                            ...shift,
                            ...newShift,
                        },
                        result
                    );
                });
            } else {
                let titleShiftType = isTrain ? "Add Train" : "Add Light Duty";
                const traineeDriver = {
                    actions: {
                        response: {
                            Driver: {
                                custom: {
                                    functionName: "getTraineeAndLightDutyDriver",
                                    get: "result",
                                    excludes: [],
                                    criteria: {
                                        routeId: shift.id,
                                    },
                                },
                            },
                        },
                    },
                };
                const responsetraineeDriver = await api.post("/api/lazy/manage/data", traineeDriver);
                let traineeDriverName = "";
                responsetraineeDriver.data.data.result.map((driver, index) => {
                    traineeDriverName += driver.driverName + ",";
                });
                const confirmation = await confirm(
                    'WARNING: By changing the Shift Type from the "' +
                    titleShiftType +
                    '" to "' +
                    newShift.extra.name +
                    '" type, you will delete any training assignments/data from "' +
                    traineeDriverName.slice(0, traineeDriverName.lastIndexOf(",")) +
                    '". Do you wish to continue?'
                );
                if (!confirmation) {
                    return true;
                }
                if (shift.driverId != "0.0.0") {
                    const driverSkillParam = {
                        actions: {
                            response: {
                                Driver: {
                                    custom: {
                                        functionName: "checkDriverAndShiftSkillMatch",
                                        get: "result",
                                        excludes: [],
                                        criteria: {
                                            driverId: shift.driverId,
                                            shiftId: newShift.extra.id,
                                        },
                                    },
                                },
                            },
                        },
                    };
                    const responseDriverSkill = await api.post("/api/lazy/manage/data", driverSkillParam);
                    if (!responseDriverSkill.data.data.result.result) {
                        const confirmation = await confirm(
                            'WARNING: "' +
                            responseDriverSkill.data.data.result.driverName +
                            '" does not have the required "' +
                            responseDriverSkill.data.data.result.shiftSkillName +
                            '" skill to be assigned this shift type. Are you sure you want to continue?'
                        );
                        if (!confirmation) {
                            return true;
                        }
                    }
                }
                const driverRouteParam = {
                    actions: {
                        response: {
                            DriverRoute: {
                                custom: {
                                    functionName: "updateDriverRouteByShift",
                                    get: "result",
                                    excludes: [],
                                    criteria: {
                                        routeId: shift.id,
                                        shiftId: newShift.extra.id,
                                        isEditTrain: isTrain,
                                        isEditDuty: !isTrain,
                                        shiftInvoiceTypeId: newShift.shiftInvoiceType,
                                        startTime: moment.utc(newShift.startTime, "H.m").format("HH:mm"),
                                        endTime: moment.utc(newShift.endTime, "H.m").format("HH:mm"),
                                        timezoneOffset: new Date().getTimezoneOffset(),
                                    },
                                },
                            },
                        },
                    },
                };
                const responseDriverRoute = await api.post("/api/lazy/manage/data", driverRouteParam);
                actions.onEditNormalShift(shift, responseDriverRoute.data.data.result);
            }
        });
    };

    const handleRefusedRescue = async () => {
        let confirmation = await confirm({
            icon: false,
            title: "Confirm Refused Rescue Shift",
            text: `Please confirm your wish to assign a Refused Rescue to "` + shift.driver.name + `"?`,
        });

        if (confirmation) {
            saveRefusedRescueShift({
                routeId: shift.routeId,
            }).then(
                async (response) => {
                    if (!response) return;
                    actions.onAddRefuseRescue(shift);
                    const paramIncident = {
                        actions: {
                            response: {
                                Incident: {
                                    custom: {
                                        functionName: "createReportIncidentForNewType",
                                        get: "result",
                                        criteria: {
                                            company: localStorage.getItem("company"),
                                            station: shift.stationId,
                                            driver: shift.driver.id,
                                            dateTime: moment(shift.shiftDate).utc().format("YYYY-MM-DD HH:mm:ss"),
                                            driverRoute: shift.id,
                                            createdDate: moment().utc().format("YYYY-MM-DD HH:mm:ss"),
                                            updatedDate: moment().utc().format("YYYY-MM-DD HH:mm:ss"),
                                        },
                                    },
                                },
                            },
                        },
                    };
                    await api.post("/api/lazy/manage/data", paramIncident);
                    return toast({
                        type: "success",
                        title: "Refuse Rescue shift status updated successfully.",
                    });
                },
                (error) => {
                    if (error.response)
                        toast({
                            type: "error",
                            title: "Error",
                            content: error.response.data.message,
                        });
                }
            );
        }
    };

    const handleAddTrainer = async () => {
        let opts = await prepareOptions({ isTrain: true });
        const { shiftInvoiceTypes, trainerShift, drivers } = opts;

        if (!trainerShift)
            return toast({
                type: "warning",
                title: "No shift type found!",
            });

        showFlexibleShiftBlockForm({
            options: {
                shiftTypes: [trainerShift],
                shiftInvoiceTypes: shiftInvoiceTypes,
                loaded: true,
            },
            title: "Add Trainer Shift",
            timeZoneShortName: shift.timezone,
            shift: {
                typeId: trainerShift.id,
                isAddTrain: true,
                routeId: shift.routeId,
                startTime: shift.startTime,
                endTime: shift.endTime,
                utcStartTime: shift.utcStartTime,
                utcEndTime: shift.utcEndTime,
                stationId: shift.stationId,
                shiftDate: shift.shiftDate,
            },
            fields: [
                {
                    component: CustomDropdown,
                    props: {
                        name: "trainees",
                        label: "Trainees",
                        Required: true,
                        type: "people",
                        multiple: true,
                        options: drivers.map((opt) => ({
                            value: opt.id,
                            name: opt.fullName,
                            profile: opt.user.image,
                        })),
                        visibleOptionsQty: 6,
                    },
                },
            ],
        }).then((newShift) => {
            if (!newShift) return;
            newShift.isTrain = true;
            newShift.trainees.map((value, index) => {
                shift.driverId = value;
                saveSpecialShift({
                    newShift,
                    related: shift,
                }).then((response) => {
                    if (!response) return;

                    const result = response.data.data.result;
                    actions.onAddTrainer(
                        {
                            ...shift,
                            ...newShift,
                        },
                        result
                    );
                });
            });
        });
    };

    const handleAddLightDuty = async () => {
        let opts = await prepareOptions({ isDuty: true });

        const { shiftInvoiceTypes, lightDutyShift, drivers } = opts;

        if (!lightDutyShift)
            return toast({
                type: "warning",
                title: "No shift type found!",
            });

        showFlexibleShiftBlockForm({
            options: {
                shiftTypes: [lightDutyShift],
                shiftInvoiceTypes: shiftInvoiceTypes,
                loaded: true,
            },
            title: "Add Light Duty Shift",
            timeZoneShortName: shift.timezone,
            shift: {
                typeId: lightDutyShift.id,
                isLightDuty: true,
                routeId: shift.routeId,
                startTime: shift.startTime,
                endTime: shift.endTime,
                utcStartTime: shift.utcStartTime,
                utcEndTime: shift.utcEndTime,
                stationId: shift.stationId,
                shiftDate: shift.shiftDate,
            },
            fields: [
                {
                    component: CustomDropdown,
                    props: {
                        name: "pairDriver",
                        label: "Pair Driver",
                        type: "people",
                        options: drivers.map((opt) => ({
                            value: opt.id,
                            name: opt.fullName,
                            profile: opt.user.image,
                        })),
                        visibleOptionsQty: 6,
                    },
                },
            ],
        }).then((newShift) => {
            if (!newShift) return;

            newShift.isDuty = true;
            shift.driverId = newShift.pairDriver;
            saveSpecialShift({
                newShift,
                related: shift,
            }).then((response) => {
                if (!response) return;

                const result = response.data.data.result;

                actions.onAddTrainer(
                    {
                        ...shift,
                        ...newShift,
                    },
                    result
                );
            });
        });
    };

    const handleSendHome = () => {
        setSendHome({
            driverId: shift.routeId,
        }).then(
            (response) => {
                if (response) {
                    shift.dateTimeEnded = moment().format("YYYY-MM-DD HH:mm");
                    shift.newNote = response.data.data.sendHome;
                    shift.isSentHome = true;
                    shift.invoiceTypeId = null;
                    actions.onSendHome(shift);
                }
            },
            (error) => console.log(error)
        );

        return toast({
            type: "success",
            title: "Sent Home Successfully!!!",
        }).then(() => setOpen(false));
    };

    const handleRemoveRequest = async (routeId, weekNumber) => {
        removeRequest(routeId, weekNumber).then((response) => {
            if (!response) return;
            const result = response.data.data.result;
            actions.onEditShift(shift, result);
        });
    };

    const handleUnSendHome = () => {
        setSendHome({
            driverId: shift.routeId,
            time: null,
        }).then(
            (response) => {
                if (response) {
                    shift.dateTimeEnded = null;
                    shift.newNote = response.data.data.sendHome;
                    shift.isSentHome = false;
                    actions.onUnSendHome(shift);
                }
            },
            (error) => console.log(error)
        );

        return toast({
            type: "success",
            title: "Un-sent Home Successfully!!!",
        }).then(() => setOpen(false));
        return true;
    };

    const popupContentRef = useRef();
    const preventClose = useRef();
    const history = useHistory();
    useOutsideClick(
        popupContentRef,
        (e) => {
            if (!preventClose.current) {
                setOpen(false);
            }
        },
        "mouseup"
    );

    useEffect(() => {
        if (localStorage.getItem("debug")) {
            console.log(shift);
        }
        getShiftProperties({
            id: shift.routeId,
        })
            .then((response) => response)
            .then((response) => {
                let extra = extraData;
                if (response && response.data && response.data.data && response.data.data.extra) {
                    extra = {
                        ...response.data.data.extra,
                        vehicle: response.data.data.extra.vehicle,
                        devices: response.data.data.extra.device,
                        invoiceType: response.data.data.extra.shiftInvoiceType,
                        kickoffLog: response.data.data.extra.kickoffLog,
                        returnToStation: response.data.data.extra.returnToStation,
                    };
                    setExtraData(extra);
                }
                if (shift.routeId) {
                    // getShiftMakeRescueCheck({
                    //     id: shift.routeId,
                    // }).then((response) => {
                    //     if (response && response.data.data.driverRoute.length <= 0) {
                            setCanMakeRescue(true);
                    //     }
                    // });
                }

                if (shift.priorShiftRouteId && extra && !extra.relatedShift) {
                    getShiftData({ id: shift.priorShiftRouteId }).then(
                        (response) =>
                            response &&
                            (response.data.data.driverRoute) &&
                            setExtraData({
                                ...extra,
                                relatedShift: response.data.data.driverRoute,
                                canMakeRescue: canMakeRescue,
                            })
                    );
                } else {
                    if (shift.isRescued && extra && !extra.relatedShift) {
                        getShiftData({ id: shift.routeId, findRescuer: true }).then(
                            (response) =>
                                response &&
                                (response.data.data.driverRoute) &&
                                setExtraData({
                                    ...extra,
                                    relatedShift: response.data.data.driverRoute,
                                    canMakeRescue: canMakeRescue,
                                })
                        );
                    }
                }
            });

        // return () => canceler && canceler.current()
    }, []);

    const updateDuration = (startTime, endTime) => {
        let estimatedWorkHours = shift && shift.driver ? (shift.driver.estimatedWorkHours ? shift.driver.estimatedWorkHours : (shift.driver.expectedWorkHours) ? shift.driver.expectedWorkHours : 0) : 0;
        if (parseFloat(estimatedWorkHours) > 40) {
            setOvertimeWarning((parseFloat(estimatedWorkHours) - 40).toFixed(2));
        } else {
            setOvertimeWarning(false);
        }
    };
    useEffect(() => {
        let startTime = null;
        let endTime = null;

        if (shift.utcStartTime != null || shift.utcStartTime !== false)
            startTime = parseFloat(shift.utcStartTime)
                .toFixed(2)
                .replace('.', ':');

        if (shift.utcEndTime != null || shift.utcEndTime !== false)
            endTime = parseFloat(shift.utcEndTime)
                .toFixed(2)
                .replace('.', ':');

        updateDuration(startTime, endTime);
    }, [shift]);

    const getDspActions = (driver) => {
        let actions = [];
        let isPastShift = moment(shift.dateCreated).format("YYYY-MM-DD") < moment().format("YYYY-MM-DD");
        if (incidents?.length) {
            actions.push({ name: "Report Incident" });
            incidents.forEach((type) => {
                if (type.token != "Dro" && type.token != "Pic" && type.token != "Thx" && type.token != "Eno" && type.token != "Cor" && type.token != "Coa") {
                    actions.push({
                        name: type.name,
                        value: `incident-${type.id}`,
                    });
                }
            });
        }

        if (
            [
                "ROLE_STATION_MANAGER",
                "ROLE_OWNER",
                "ROLE_ASSISTANT_STATION_MANAGER",
                "ROLE_LEAD_DRIVER",
                "ROLE_OPERATIONS_ACCOUNT_MANAGER",
            ].includes(localStorage.getItem("currentUserRole"))
        ) {
            actions.push({ name: "Password Reset", value: "password-reset", divider: true });
            { !isPastShift && actions.push({ name: "Remove Shifts", value: `removeShifts` }) };
        }

        return actions;
    };

    const getTrashActions = (driver) => {
        let actions = [];
        actions.push({ name: "Delete/Drop Options" });
        if (driver?.routeCode?.length > 0) {
            actions.push({ name: "Delete Shift/Route", value: "delete-shift-and-route" });
        } else {
            actions.push({ name: "Delete Shift", value: "delete-shift", divider: true });
        }
        if (driver.category != 4 && driver.category != 8 && driver.isOpenShift == false && driver.driverId !== null && localStorage.getItem('role') != 'ROLE_DISPATCHER_WITH_NO_OPEN_SHIFT_FEATURE') {
            actions.push({ name: "Delete Shift/Move Route to Open", value: "drop-route-to-open" });
        }

        return actions;
    };

    const handleDspActions = async (driver, option) => {
        let actionType = option.value;
        const hideLoading = loadingDialog();
        let param;

        switch (actionType) {
            case "password-reset":
                return passwordReset(driver);
            case "removeShifts":
                return removeShifts(driver);
            default:
                if (actionType.match("incident-")) {
                    const incidentsApi = (await import("../../Incidents/api")).default;
                    openReportIncidentForm({
                        station: driver.stationId,
                        driver: driver.driverId,
                        incidentType: actionType.replace("incident-", ""),
                        driverRoute: driver.id.toString(),
                        date: moment(driver.shiftDate).format("YYYY-MM-DD"),
                        time: moment.utc().format("HH:mm"),
                        dropAndPicRoute: false,
                    }).then(
                        async ({ data: incident, action }) => {
                            if (!incident) return false;
                            const hide = loadingDialog();
                            const response = await incidentsApi
                                .startIncident(incident)
                                .then((res) => {
                                    driver.incident = {
                                        id: res.data.data.result.id,
                                        title: null,
                                        type: res.data.data.result.incidentType.name,
                                        dateTime: moment(res.data.data.result.dateTime).format("yyyy-mm-dd HH:mm:ii"),
                                    };
                                    if (
                                        res.data.data.result.incidentType.token == "Cal" ||
                                        res.data.data.result.incidentType.token == "Ncn"
                                    ) {
                                        driver.invoiceTypeId = null;
                                        driver.hasCallOutOrNcns = true;
                                    }
                                    if (driver.routeCode.length > 0) {
                                        let newDriver = driver;
                                        // newDriver.driverId = '0.0.0';
                                        // newDriver.isOpenShift = true;
                                        // actions.moveToOpenRoute(
                                        //     driver,
                                        //     newDriver
                                        // );
                                        actions.onIncidentShift(driver, incident);
                                        res && action === "next"
                                            ? incidentPromise(res.data.data.result.id)
                                            : toast({
                                                type: "Success",
                                                title: "Incident successfully reported",
                                                content: (
                                                    <Link
                                                        style={{ color: "inherit" }}
                                                        to={`/incidents/${res.data.data.result.id}`}
                                                    >
                                                        View incident
                                                    </Link>
                                                ),
                                                timeout: 8000,
                                            });
                                    } else {
                                        actions.onIncidentShift(driver, incident);
                                        res && action === "next"
                                            ? incidentPromise(res.data.data.result.id)
                                            : toast({
                                                type: "Success",
                                                title: "Incident successfully reported",
                                                content: (
                                                    <Link
                                                        style={{ color: "inherit" }}
                                                        to={`/incidents/${res.data.data.result.id}`}
                                                    >
                                                        View incident
                                                    </Link>
                                                ),
                                                timeout: 8000,
                                            });
                                    }
                                })
                                .then(hide);

                            return response;
                        },
                        (error) => {
                            if (error.response)
                                toast({
                                    type: "error",
                                    title: "Error",
                                    content: error.response.data.message,
                                });
                        }
                    );
                }
                break;
        }
    };

    const handleDropRouteActions = async (driver, option) => {
        let action = option.value;
        // check other station shift
        if(driver.driverId != "0.0.0") {
            const params = {
                station: driver.stationId,
                checkIncident: true,
                driver: [driver.driverId],
                date: driver.dateCreated,
            };
            let res = await API.checkDriverRoute(api, params);
            let dialogMsg = "";
            if (action == "restore-shift") {
                if(res.success === true && res.isTodayPast === true) {
                    dialogMsg = "Driver " + res.availableDriverIds[0].name + " already have a scheduled shift in " + res.availableDriverIds[0].code +". \n You can't delete a shift/move a change to open today or past shift..!";
                    await alert({
                        text: dialogMsg,
                        btnText: "Cancel",
                    });
                    return false;
                } else if (res.success === true && res.availableDriverIds.length > 0) {
                    if(res.availableDriverIds[0].type == "temp") {
                        dialogMsg = "Driver " + res.availableDriverIds[0].name + " already have a scheduled shift in " + res.availableDriverIds[0].code +". \n You can't restore the shift, please publish or discard temp changes to other station shifts then after creating..!";
                        await alert({
                            text: dialogMsg,
                            btnText: "Cancel",
                        });
                        return false;
                    }
                    dialogMsg = "Driver " + res.availableDriverIds[0].name + " already have a scheduled shift in " + res.availableDriverIds[0].code +". \n The original shift will be restored and the current shift will be deleted.";
                    await dialog({
                        text: dialogMsg,
                        buttons: [
                            {
                                text: "Cancel",
                                callback: async () => {
                                    return false;
                                },
                            },
                            {
                                type: "primary",
                                text: "Confirm",
                            },
                        ],
                    }).then(async (dialogResponse) => {
                        if (!dialogResponse) return;
                        
                        recallShift({
                            driverRouteId: driver.routeId,
                            stationId: driver.stationId,
                            driverId: driver.driverId,
                            date: driver.dateCreated,
                            isRestore: true
                        });

                        actions.onRestoreShift(shift, {
                            ...shift,
                            isRestore: true,
                        });

                        return toast({
                            type: "success",
                            title: "Shift successfully recall.",
                        });
                        return;
                    });
                    return false;
                }
            } else if (action == "recall-ghost-shift") {
                if (res.availableDriverIds.length > 0 && res.availableDriverIds[0].type === 'temp') {
                    dialogMsg = "Driver " + res.availableDriverIds[0].name + " already have a scheduled shift in " + res.availableDriverIds[0].code +". \n You can not Recall shift, please publish or discard temp changes other station shift then after recall..!";
                    await alert({
                        text: dialogMsg,
                        btnText: "Cancel",
                    });
                    return false;
                }
            }
        }
        switch (action) {
            case "delete-shift-and-route":
                const conf1 = await confirm({
                    icon: true,
                    text: "This will treat this Shift and Route Code as scheduling errors and remove them from today's record.",
                    setOpen: setOpen
                });

                if (!conf1) return;

                if (driver.isRescuer) {
                    let response = await dropRescueLoadoutShift({
                        driverId: driver.routeId,
                        incidentId: null,
                    });
                    actions.onDropRescue(
                        driver,
                        response.data.data.dropRescueShift.rescuerDriverRoute,
                        response.data.data.dropRescueShift.rescuedDriverRoutesArr
                    );
                } else {
                    dropLoadoutShift({
                        driverId: driver.routeId,
                        incidentId: null,
                        isMultiDelete: false
                    });

                    actions.onRemoveRoute(driver);
                }

                return toast({
                    type: "success",
                    title: "Shift and Route successfully removed.",
                });
                return;

            case "delete-shift":
                const conf2 = await confirm({
                    icon: true,
                    text: "This will treat this Shift as a scheduling error and remove only the driver's shift from today's record.",
                    notificationText: "Notify driver of the shift's removal via SMS",
                    setOpen: setOpen
                });
                if (!conf2) return;

                const { sendNotification: notifyDriver } = conf2;

                dropLoadoutShift({
                    driverId: driver.routeId,
                    incidentId: null,
                    notifyDriver: notifyDriver,
                    isMultiDelete: false,
                });

                actions.onRemoveRoute(driver);

                return toast({
                    type: "success",
                    title: "Shift successfully removed.",
                });
                return;

            case "restore-shift":
                const conf3 = await confirm({
                    icon: true,
                    text: "Are you sure you want to restore this shift?",
                    setOpen: setOpen
                });
                if (!conf3) return;

                restoreShift({
                    driverId: driver.routeId
                });

                actions.onRestoreShift(shift, {
                    ...shift,
                    isRestore: false,
                });

                return toast({
                    type: "success",
                    title: "Shift successfully restored.",
                });
                return;

            case "drop-route-to-open":
                const openRouteConfi = await confirm({
                    icon: true,
                    text: "This will treat this Shift as a scheduling error and remove it from today's record but will place the Route Code into Open Routes.",
                    setOpen: setOpen
                });
                if (!openRouteConfi) return;

                if (driver.isNew) {
                    createOpenRouteTemp({
                        driverId: driver.routeId,
                        incidentId: null,
                        isMultiDelete: false
                    });
                } else {
                    createOpenRoute({
                        driverId: driver.routeId,
                        incidentId: null,
                        isMultiDelete: false
                    });
                }
                driver.isNew = false;
                actions.moveToOpenRoute(driver, driver);


                // actions.onRemoveRoute(
                //     driver
                // );
                return toast({
                    type: "success",
                    title: "Shift and Route successfully moved in open route.",
                });

                return;

            case "recall-ghost-shift":
                let driverName = driver.driver.name;
                let driverStationCode = driver.stationCode;
                const conf4 = await confirm({
                    icon: true,
                    text: `Are you sure you want to recall ${driverName} from ${driverStationCode}? The original shift will be restored and the current shift will be deleted.`,
                    setOpen: setOpen,
                });
                if (!conf4) return;

                recallShift({
                    driverRouteId: driver.routeId,
                    stationId: driver.stationId,
                    driverId: driver.driverId,
                    date: driver.dateCreated,
                    isRestore: false
                });

                actions.onRestoreShift(shift, {
                    ...shift,
                    isRestore: true,
                });

                return toast({
                    type: "success",
                    title: "Shift successfully recall.",
                });
                return;
            
            default:
                break;
        }
    };

    const shiftActions = getShiftActions({ shift, canMakeRescue });
    const footerActions = shiftActions.map((action) => (
        <Button
            size="small"
            onClick={() => {
                // eslint-disable-next-line default-case
                switch (action.action) {
                    case "add-second-shift":
                        return handleAddSecondShift();

                    case "add-rescue-shift":
                        return handleAddRescue();

                    case "make-light-duty":
                        return handleAddLightDuty();

                    case "make-trainer":
                        return handleAddTrainer();

                    case "refused-rescue":
                        return handleRefusedRescue();

                    case "send-home":
                        return handleSendHome();

                    case "un-send-home":
                        return handleUnSendHome();

                    case "disapprove":
                        return handleRemoveRequest(shift.id.toString(), shift.currentWeek)();
                }
            }}
            type={action.type || "default"}
        >
            {!action.icon
                ? undefined
                : React.createElement(action.icon, {
                    size: "16px",
                    style: {
                        verticalAlign: "sub",
                        marginRight: 4,
                        marginLeft: -4,
                    },
                })}
            {action.label}
        </Button>
    ));

    const PasswordResetDailog = ({ success, cancel, driver }) => {
        const { register, handleSubmit, errors, reset, getValues } = useForm();
        const { password, driverId } = getValues();
        const [loading, setLoading] = useState(false);
        const [allDrivers, setAllDrivers] = useState([]);
        const [driverEmail, setDriverEmail] = useState(driver.email);
        const [oldPasswords, setOldPasswords] = useState(driver.oldPasswords);
        const errorMessages = {
            password: {
                required: "You need to provide a password",
                pattern: "You password must respect all rules",
            },
        };
        const fetchList = async () => {
            setAllDrivers(await API.getAllDrivers(api, driver.stationId));
        };
        useEffect(() => {
            fetchList();
        }, []);

        const editPasswordReset = async (data) => {
            setLoading(true);
            let responsePassword = await API.passwordReset(api, data.driverId, data.password);
            if (responsePassword.data.success) {
                let responseMail = await API.passwordResetMail(
                    api,
                    localStorage.getItem("company"),
                    driver.email,
                    driver.driver.name.trim(),
                    data.password
                );
                if (responseMail) {
                    success(true);
                    toast({
                        type: "success",
                        title: "Password successfully changed.",
                        timeout: 8000,
                        useIcon: true,
                        useClose: true,
                    });
                } else {
                    success(false);
                    toast({
                        type: "error",
                        title: "Error in sending mail !!!",
                        timeout: 8000,
                        useIcon: true,
                        useClose: true,
                    });
                }
            } else {
                success(false);
                let messageTxt = responsePassword.data?.message ? responsePassword.data?.message : "Error in password reset !!!";
                toast({
                    type: "error",
                    title: messageTxt,
                    timeout: 8000,
                    useIcon: true,
                    useClose: true,
                });
            }
        };

        return (
            <Modal width="340px" title="Password Reset" visible={true} setVisible={cancel} disabled={loading}>
                {loading ? (
                    <div style={{ textAlign: "center" }}>
                        <Loading style={{ width: "40px" }} />
                    </div>
                ) : (
                    <form name="password_reset_form" onSubmit={handleSubmit(editPasswordReset)}>
                        <CustomDropdown
                            ref={register({
                                required: true,
                            })}
                            Required={true}
                            name="driverId"
                            label="Driver"
                            defaultValue={driver.userId}
                            options={allDrivers.map((s) => ({ name: s.name.toString(), value: s.userId.toString() }))}
                            valid={errors.driverId && errors.driverId.length}
                            error={errors.driverId && "Please select a driver type"}
                            onChange={(d) => {
                                    let currentDriver = allDrivers.filter((driver) => {
                                        return driver.userId.toString() === d.toString()
                                    });    
                                setDriverEmail(currentDriver[0].email);
                                setOldPasswords(currentDriver[0].oldPasswords);
                            }}
                        />
                        <Spacer top={3} />
                        <Password
                            name="password"
                            label="Password"
                            help={<><ul><li>Include lowercase letters, UPPERCASE letters, numbers, and symbols</li><li>Be at least 8 characters long</li><li>Not have 3 consecutive or identical characters</li><li>Not contain parts of your username</li><li>Not match any of your last 6 passwords</li></ul></>}
                            ref={register({
                                required: true,
                                validate: (value) => {
                                    if (value) {
                                        return validatePassword(value, driverEmail, oldPasswords);
                                    }
                                }
                            })}
                            error={errors.password && errors.password.message}
                            valid={!errors.password && password && password.length > 0}
                        />
                        <Spacer top={5} style={{ textAlign: "right" }}>
                            <Button type="primary">Save</Button>
                        </Spacer>
                    </form>
                )}
            </Modal>
        );
    };

    const passwordReset = async (driver) => {
        return dialogPromise((success, cancel) => (
            <PasswordResetDailog success={success} cancel={cancel} driver={driver} />
        ));
    };

    const RemoveShiftsDialog = ({ success, cancel, driver }) => {
        const today = new Date();
        const ShiftStatuses = {
            1: "PTO",
            2: "UPTO",
            3: "HOLIDAY"
        }
        const [loading, setLoading] = useState(false);
        const [history, setHistory] = useState([]);
        const [editHistory, setEditHistory] = useState(null);
        const [removeType, setRemoveType] = useState(null)
        const [showForm, setShowFrom] = useState(false)

        useEffect(() => {
            const getRemovedShift = async () => {
                const params = {
                    actions: {
                        response: {
                            DriverRemovedShifts: {
                                custom: {
                                    functionName: "findFutureRemovedDriverShifts",
                                    get: "removedDriverShifts",
                                    criteria: {
                                        removeType: removeType,
                                        driverId: driver.driverId,
                                    },
                                    includes: (removeType == 2) ? {
                                        0: 'id',
                                        1: 'removeType',
                                        2: 'startDate',
                                        3: 'endDate',
                                        4: {
                                            'driver': 'id'
                                        },
                                        5: 'shiftStatus',
                                        6: 'isVoluntary'
                                    } : {}
                                }
                            },
                        },
                    },
                };
                const res = await api.post("/api/lazy/manage/data", params);
                if (removeType == "2") {
                    setEditHistory(res.data.data.removedDriverShifts?.[0] ?? null);
                    setShowFrom(true)
                } else {
                    setHistory(res.data.data.removedDriverShifts);
                    setEditHistory(null);
                    setShowFrom(false);
                }
            }
            if (removeType == "1" || removeType == "2") {
                getRemovedShift();
            }
        }, [removeType]);

        const handleEdit = (data) => {
            const editHistory = history.find(h => h.startDate === data.startDate && h.endDate === data.endDate);
            setEditHistory(editHistory);
            setShowFrom(true)
        }

        const handleAdd = () => {
            setEditHistory(null);
            setShowFrom(true);
        }

        const handleDeleteRemoveShiftVacation = async (data) => {
            //loadingHandler(true);
            const deleteVacationShifts = {
                actions: {
                    response: {
                        DriverRemovedShifts: {
                            custom: {
                                functionName: "deleteRemoveShiftVacation",
                                get: "result",
                                criteria: {
                                    removeType: data.removeType,
                                    driverId: driver.driverId,
                                    startDate: moment(data.startDate.timestamp * 1000).format("YYYY-MM-DD"),
                                    endDate: moment(data.endDate.timestamp * 1000).format("YYYY-MM-DD"),
                                    groupId: data?.groupId
                                },
                                includes: {},
                            },
                        },
                    },
                },
            };
            const deleteShiftsRes = await api.post("/api/lazy/manage/data", deleteVacationShifts);

            if (deleteShiftsRes) {
                success(true);
                toast({
                    type: "success",
                    title: `${data.removeType == "1" ? "Vacation" : "Terminations"} delete remove shift successfully.`,
                    timeout: 8000,
                    useIcon: true,
                    useClose: true,
                });
                actions.onRemoveDriverVacationOrTerminationShift();
            } else {
                success(false);
                toast({
                    type: "error",
                    title: "Error in delete vacation !!!",
                    timeout: 8000,
                    useIcon: true,
                    useClose: true,
                });
            }
            return false;
        }

        return (
            <Modal width="500px" title="Remove Shifts" visible={true} setVisible={cancel}>
                {loading ? (
                    <div style={{ textAlign: "center" }}>
                        <Loading style={{ width: "40px" }} />
                    </div>
                ) : (
                    <>
                        <Label>Remove shifts of driver due to </Label>
                        <RadioBox
                            name="removeType"
                            options={[
                                { label: "Vacation", value: "1" },
                                { label: "Remove From Schedule", value: "2" },
                            ]}
                            onChange={(e) => setRemoveType(e.target.value)}
                        />
                        <Spacer top={3}></Spacer>
                        {removeType == "1" ? (
                            <Spacer bottom={3}>
                                <Table>
                                    <li className='header'>
                                        <TableData width='100px'>From</TableData>
                                        <TableData width='100px'>To</TableData>
                                        <TableData width='80px' style={{ verticalAlign: 'middle', height: '40px' }} >
                                            <Icon.Plus size='20px' color='#7C98B6' onClick={() => handleAdd()} />
                                        </TableData>
                                    </li>
                                    {history.length > 0 ? history.map((data) => {
                                        return (
                                            <li className='header' key={data.id}>
                                                <TableData width='100px'>{moment(data.startDate.timestamp * 1000).format('MM/DD/YYYY')}</TableData>
                                                <TableData width='100px'>{moment(data.endDate.timestamp * 1000).format('MM/DD/YYYY')}</TableData>
                                                <TableData width='80px' style={{ verticalAlign: 'middle', height: '40px' }}>
                                                    <Spacer inline right={3}>
                                                        <Icon.Edit size='20px' color='#7C98B6'
                                                            style={{ position: 'relative', top: '3px' }} onClick={() => handleEdit(data)} />
                                                    </Spacer>
                                                    <Icon.Times size='20px' color='#7C98B6' onClick={() => handleDeleteRemoveShiftVacation(data)} />
                                                </TableData>
                                            </li>
                                        );
                                    }) : <TableData>No Data found.</TableData>}
                                </Table>
                            </Spacer>
                        ) : ""}
                        {showForm && <RemoveShiftForm driver={driver} removeStatus={removeType} editHistory={editHistory} loadingHandler={setLoading} success={success} cancel={cancel} />}
                    </>
                )}
            </Modal>
        );
    };

    const removeShifts = async (driver) => {
        return dialogPromise((success, cancel) => (
            <RemoveShiftsDialog success={success} cancel={cancel} driver={driver} />
        ));
    };

    const RemoveShiftForm = ({ driver, removeStatus, editHistory, loadingHandler, success, cancel }) => {
        const [vacationPeriodRange, setVacationPeriodRange] = useState(null);
        const [vacationPeriodRangeFormat, setVacationPeriodRangeFormat] = useState(null);
        const { register, handleSubmit, errors, watch, getValues, reset } = useForm({
            defaultValues: { removeType: removeStatus },
        });
        let groupId = moment().valueOf();
        const removeType = watch('removeType');

        const tomorrow = new Date();

        tomorrow.setDate(tomorrow.getDate() + 1);

        const onSubmit = async (data) => {
            const [startDate, endDate] = data.removeType == "1" ? data.vacationPeriod.split(" – ") : [data.terminationStartDate];
            loadingHandler(true);
            const removeShifts = {
                actions: {
                    response: {
                        DriverRemovedShifts: {
                            custom: {
                                functionName: "removeDriveShifts",
                                get: "result",
                                criteria: {
                                    id: data.id,
                                    removeType: data.removeType,
                                    driver: driver.driverId,
                                    startDate,
                                    endDate,
                                    groupId: editHistory?.groupId != null ? editHistory?.groupId : groupId
                                },
                                includes: ["deletedDriverRoutesIds"],
                            },
                        },
                    },
                },
            };
            const removeShiftsRes = await api.post("/api/lazy/manage/data", removeShifts);

            if (removeShiftsRes) {
                success(true);
                let successMessage = (data.removeType == "1") ? "Vacation dates successfully updated for this driver." : "Driver added successfully 'Remove from the schedule' queue. It'll remove from schedule automatically on the selected date.";
                toast({
                    type: "success",
                    title: successMessage,
                    timeout: 8000,
                    useIcon: true,
                    useClose: true,
                });
                actions.onRemoveDriverVacationOrTerminationShift();
            } else {
                success(false);
                toast({
                    type: "error",
                    title: "Error in deleteing shifts !!!",
                    timeout: 8000,
                    useIcon: true,
                    useClose: true,
                });
            }
            return false;
        };

        useEffect(() => {
            if (editHistory) {
                if (editHistory.removeType == "1") {
                    setVacationPeriodRange([moment(editHistory.startDate.timestamp * 1000).format("YYYY-MM-DD"), moment(editHistory.endDate.timestamp * 1000).format("YYYY-MM-DD")]);
                    setVacationPeriodRangeFormat(([moment(editHistory.startDate.timestamp * 1000).format("MM/DD/YYYY"), moment(editHistory.endDate.timestamp * 1000).format("MM/DD/YYYY")]))
                }
                reset({
                    id: editHistory.id,
                    //shiftStatus:(editHistory.shiftStatus).toString(),
                    //isVoluntary:editHistory.isVoluntary,
                    removeType: (editHistory.removeType).toString(),
                    ...(editHistory.removeType == "2" && { terminationStartDate: moment(editHistory.startDate.timestamp * 1000).format("YYYY-MM-DD") }),
                })
            } else {
                reset({
                    removeType: removeStatus
                })
                setVacationPeriodRange(null);
                setVacationPeriodRangeFormat(null);
            }
        }, [editHistory])

        return (
            <form name="removeShiftForm" onSubmit={handleSubmit(onSubmit)}>
                <input type="hidden" name="removeType" ref={register({ required: true })} />
                <input type="hidden" name="id" ref={register} />
                {removeType == "1" && (
                    <>
                        <DatePicker
                            key={vacationPeriodRange}
                            label="Select Date Period"
                            className="picker"
                            name="vacationPeriod"
                            ref={register({
                                required: "Field Required",
                            })}
                            error={errors.vacationPeriod && errors.vacationPeriod.message}
                            size={"large"}
                            disableDates={{
                                before: tomorrow,
                            }}
                            range="date"
                            value={vacationPeriodRangeFormat && vacationPeriodRangeFormat.join(" – ")}
                            defaultRangeValue={
                                vacationPeriodRange && [
                                    new Date(vacationPeriodRange[0]),
                                    new Date(vacationPeriodRange[1]),
                                ]
                            }
                        />

                        {/* <Spacer top={3}>
                            <Label>Unavailable Time Off Shift with below Status</Label>
                            <RadioBox
                                ref={register({ required: "Field Required" })}
                                name="shiftStatus"
                                options={[
                                    { label: "Paid", value: "1" },
                                    { label: "Unpaid", value: "2" },
                                    { label: "Holiday", value: "3" },
                                ]}
                                Required={true}
                                error={errors.shiftStatus && errors.shiftStatus.message}
                            />
                            <Spacer top={3}></Spacer>
                            <CheckBox
                                ref={register()}
                                name="isVoluntary"
                                options={[{ value: true, label: "Voluntary" }]}
                            />
                        </Spacer> */}
                    </>
                )}
                {removeType == "2" && (
                    <DatePicker
                        label="Effective Date"
                        className="picker"
                        name="terminationStartDate"
                        ref={register({
                            required: "Field Required",
                        })}
                        error={errors.terminationStartDate && errors.terminationStartDate.message}
                        size={"large"}
                        disableDates={{
                            before: tomorrow,
                        }}
                    />
                )}
                <Spacer top={3} />
                <Spacer top={5} style={{ textAlign: "right" }}>
                    <Button type="primary">{editHistory ? "Update" : "Save"}</Button>
                </Spacer>
            </form>
        );
    };

    const TimeLineDailog = ({ success, cancel, driverRouteId, driverId }) => {
        const [loading, setLoading] = useState(false);
        // const [allDrivers, setAllDrivers] = useState([]);
        const [driverRoute, setDriverRoute] = useState([]);
        // const [driver, setDriver] = useState({ profileImage: null, position: "Delivery Associate", fullName: "" });

        const fetchTimeLine = async () => {
            setLoading(true);
            let data = await fetchPopupTimelineData(api, driverId, driverRouteId, shift.shiftDate);
            setDriverRoute(data.routes.driverRoutes.filter((i) => i.line.some((e) => e.createdAt !== null)));
            setLoading(false);
        };

        useEffect(() => {
            fetchTimeLine();
        }, []);

        return (
            <Modal width="480px" title="Timeline" visible={true} setVisible={cancel} disabled={loading}>
                {loading ? (
                    <div style={{ textAlign: "center" }}>
                        <Loading style={{ width: "40px" }} />
                    </div>
                ) : (
                    <>
                        <SimpleScroll>
                            {driverRoute.length > 0 ? (
                                <SmartTimeLine data={driverRoute} />
                            ) : (
                                <div style={{ textAlign: "center", fontSize: "16px", color: "#707070" }}>
                                    No timeline found for this driver!!!
                                </div>
                            )}
                        </SimpleScroll>
                    </>
                )}
            </Modal>
        );
    };

    const Timeline = async (driverRouteId, driverId) => {
        return dialogPromise((success, cancel) => (
            <TimeLineDailog success={success} cancel={cancel} driverRouteId={driverRouteId} driverId={driverId} />
        ));
    };

    const SmartTimeLine = ({ data }) => {
        return data.map((item, index) => (
            <div key={index}>
                <DateContent>{moment(item.date.substr(0, 10)).format("ddd, MMM Do")}</DateContent>
                <Spacer bottom={2} />
                <RowTimeline data={item} date={item.date} />
                <Spacer bottom={5} />
            </div>
        ));
    };

    return (
        <ShiftPopupStyle ref={popupContentRef}>
            {shift.deleted == true && (
                <RestoreBlockStyle>
                    {shift.isGhostMode == false ? (
                        <>
                            {shift.isRestore == true || shift.isRestore == "1" ? (
                                <>
                                    <span>
                                        Shift restore changes not published yet. <br />
                                        Please publish it.
                                    </span>
                                </>
                            ) : (
                                <>
                                    <span>
                                        Manually deleted shift by {shift.deletedBy} at {shift.deletedAt}
                                    </span>
                                    <Button
                                        style={{
                                            whiteSpace: "nowrap",
                                            opacity: 1,
                                            color: "white",
                                            background: "#F7931E",
                                        }}
                                        type={"primary"}
                                        Type={"submit"}
                                        onClick={() => {
                                            handleDropRouteActions(shift, { value: "restore-shift" });
                                        }}
                                    >
                                        Restore
                                    </Button>
                                </>
                            )}
                        </>
                    ) : (shift.isGhostMode == true &&
                        <>    
                        <span>
                            Manually moved to {shift?.stationCode} by {shift.deletedBy} at {shift.deletedAt}
                        </span>
                        <Button
                            style={{
                                whiteSpace: "nowrap",
                                opacity: 1,
                                color: "white",
                                background: "#F7931E",
                            }}
                            type={"primary"}
                            Type={"submit"}
                            onClick={() => {
                                handleDropRouteActions(shift, { value: "recall-ghost-shift" });
                            }}
                        >
                            Recall
                        </Button>
                        </>
                    )}
                </RestoreBlockStyle>
            )}
            <div className="header" data-shift={shift.id ? shift.id : ""}>

                <Spacer inline className="header-title">
                    <span className="circle" style={{ background: shift.bg }} />
                    <Spacer inline left={1} />
                    <h4 className="title">{shift.type}</h4>
                </Spacer>
                {canModify && (
                    <div style={{ display: 'flex' }}>
                        {canDeleteModify && shift.isOpenShift == 0 && shift.incident == null && ![8,9].includes(shift.category) && (
                            <>
                                <Action
                                    type="cancel"
                                    compact={true}
                                    onClick={() => {
                                        document.getElementById(`driver-${shift.id}-dspactions`).click();
                                    }}
                                >
                                    <Icon.DspwNetwork size="20px" color={Theme.colors.primary} />
                                </Action>
                                <Dropdown
                                    className="just-options"
                                    id={`driver-${shift.id}-dspactions`}
                                    visibleOptionsQty={100}
                                    options={getDspActions(shift)}
                                    onSelect={(option) => handleDspActions(shift, option)}
                                />
                            </>
                        )}
                        {(canDeleteModify || (!canDeleteModify && (shift.isRestore == true || shift.isRestore == "1"))) && <Action
                            type="cancel"
                            compact={true}
                            onClick={() => {
                                if (shift.isRescuer) handleEditRescue();
                                else if (shift.deleted == true) handleDeletedShiftEdit(true);
                                else if (shift.isTrain) handleEditTrain(true);
                                else if (shift.isDuty) handleEditTrain(false);
                                else if (shift.isSecondShift) handleSecondShiftEdit(false);
                                else handleEdit();
                            }}
                        >
                            <Icon.Edit size="20px" color={Theme.colors.info.border} />
                        </Action>}
                        {canDeleteModify && <>
                            <Action
                                type="cancel"
                                compact={true}
                                onClick={() => {
                                    document.getElementById(`driver-${shift.id}-dspactions1`).click();
                                }}
                            >
                                <Icon.Trash size="20px" color={Theme.colors.error.text} />
                            </Action>
                            <DropdownWrapper>
                                <Dropdown
                                    className="just-options"
                                    id={`driver-${shift.id}-dspactions1`}
                                    options={getTrashActions(shift)}
                                    onSelect={(option) => handleDropRouteActions(shift, option)}
                                    visibleOptionsQty={100}
                                />
                            </DropdownWrapper>
                        </>}
                    </div>
                )}
            </div>
            {(shift.hasAnySinglePunch || (!shift.hasCallOutOrNcns && !shift.isSentHome && !shift.isTimeOff)) && (
                <Spacer top={1}>
                    <Text>
                        {shift.hour} {shift.timezone ? ` - ${shift.timezone}` : ""}
                    </Text>
                </Spacer>
            )}
            {(shift.hasAnySinglePunch || (!shift.hasCallOutOrNcns && !shift.isSentHome && !shift.isTimeOff)) && (
                <Spacer top={4}>
                    <Spacer style={{ display: "flex", alignItems: "center" }}>
                        <Spacer right={5}>
                            <Icon.Clock
                                size="16px"
                                color={Theme.colors.info.border}
                                style={{ verticalAlign: "middle" }}
                            />
                            <Spacer inline left={2}>
                                <Text
                                    data-shitf={JSON.stringify({
                                        startTime: shift.startTime,
                                        s1: shift.startTime.toFixed(2).replace(".", ":"),
                                        endTime: shift.endTime,
                                        s2: shift.endTime.toFixed(2).replace(".", ":"),
                                    })}
                                >
                                    {calculateDuration(
                                        shift.startTime.toFixed(2).replace(".", ":"),
                                        shift.endTime.toFixed(2).replace(".", ":"),
                                        0
                                    )}{" "}
                                    {shift.time != "00:00"
                                        ? `(${calculateDuration(
                                            shift.startTime.toFixed(2).replace(".", ":"),
                                            shift.endTime.toFixed(2).replace(".", ":"),
                                            shift.time
                                        )})`
                                        : ""}
                                </Text>
                            </Spacer>
                        </Spacer>
                        <Spacer>
                            <Icon.Lunch
                                size="16px"
                                color={Theme.colors.info.border}
                                style={{ verticalAlign: "middle" }}
                            />
                            <Spacer inline left={2}>
                                <Text>
                                    {shift.time}{" "}
                                    {shift.time != "00:00"
                                        ? `(${covertTimeInNumber(databaseToMilliseconds(shift.time))})`
                                        : ""}
                                </Text>
                            </Spacer>
                        </Spacer>
                    </Spacer>
                </Spacer>
            )}
            {overtimeWarning && (
                <Spacer top={3}>
                    <Alert.Warning
                        title='Overtime warning'
                        content={`This driver will be in overtime by ${overtimeWarning} hours`}
                    />
                </Spacer>
            )}
            {shift.driver && shift.driver.id != "0.0.0" && (
                <Spacer
                    top={
                        shift.hasAnySinglePunch || (!shift.hasCallOutOrNcns && !shift.isSentHome && !shift.isTimeOff)
                            ? 4
                            : 1
                    }
                    inline
                >
                    <ThirdTitle style={{ fontSize: Theme.font.small.size }}>
                        Driver <Icon.Notes size="16px" color={Theme.colors.info.border} />
                    </ThirdTitle>
                    <Text>{shift.driver.name}</Text>
                    <Spacer top={2} />
                    {shift.category != 9 && canModify && (canDeleteModify || (!canDeleteModify && (shift.isRestore == true || shift.isRestore == "1"))) ? (
                        <DispatcherNote shift={shift} actions={actions} editShift={editShift} editDeletedShiftNote={editDeletedShiftNote} />
                    ) : (
                        <>
                            <ThirdTitle>Dispatcher Note</ThirdTitle>
                            <Text>{shift.newNote}</Text>
                        </>
                    )}
                </Spacer>
            )}

            {shift.incident && (
                <Spacer top={2}>
                    <ThirdTitle style={{ fontSize: Theme.font.small.size }}>Incident</ThirdTitle>
                    <Text>
                        <Link href={`/incidents/${shift.incident.id}`} target="_blank">
                            {shift.incident.title} ({shift.incident.type})
                        </Link>
                    </Text>
                </Spacer>
            )}

            {shift.requestApprovedBy &&
                shift.isRequested === true &&
                shift.isRequestCompleted === false &&
                shift.isOpenShift === true &&
                shift.reasonForReqest !== "" && (
                    <Spacer top={5}>
                        <ThirdTitle style={{ fontSize: Theme.font.small.size }}>Reason for request</ThirdTitle>
                        <Text>{shift.reasonForReqest}</Text>
                    </Spacer>
                )}

            {extraData && (
                <Spacer top={5}>
                    <ThirdTitle style={{ fontSize: Theme.font.small.size }}>Invoice Type</ThirdTitle>
                    <Text>{extraData.invoiceType ? extraData.invoiceType.name : "–"}</Text>
                </Spacer>
            )}
            {schedule && (
                <Spacer top={5}>
                    <ThirdTitle style={{ fontSize: Theme.font.small.size }}>Schedule</ThirdTitle>
                    <Text>{schedule}</Text>
                </Spacer>
            )}
            {shift?.paired?.concat(shift.trainee).length > 0 && !shift.hasPaired && (
                <Spacer top={5}>
                    <ThirdTitle style={{ fontSize: Theme.font.small.size }}>
                        {shift?.paired?.length ? "Paired Driver" : "Trainees"}
                    </ThirdTitle>
                    <Spacer top={1} style={{ display: "flex" }}>
                        {shift?.paired?.concat(shift.trainee).map(
                            (d, k) => (
                                <Driver key={k} name={d.driverName} size="20px" />
                            )
                            /*
                            todo shift shift block and, when clicked, open hightlight that shift in schedule
                            <ShiftBlock
                                type={extraData.relatedShift.type}
                                hour={extraData.relatedShift.hour}
                                background={extraData.relatedShift.bg}
                                color={extraData.relatedShift.color}
                            />
                            */
                        )}
                    </Spacer>
                </Spacer>
            )}
            {(shift.priorShiftRouteId && !shift.isSecondShift) || shift.isRescued || shift.isRescuer ? (
                <Spacer top={5}>
                    <ThirdTitle style={{ fontSize: Theme.font.small.size }}>
                        {shift.priorShiftType === "Duty" && "Paired Driver"}
                        {shift.priorShiftType === "Trainer" && "Trainer"}
                        {shift.isRescuer && "Rescuing"}
                        {shift.isRescued && "Rescuer"}
                    </ThirdTitle>

                    {shift.isRescuer && (
                        <Spacer top={1} style={{ display: "flex" }}>
                            {shift.rescuers.map((d, k) => (
                                <Driver
                                    key={k}
                                    name={d.driverName}
                                    jobTitle={
                                        shift.isRescuer && shift.numberOfPackage > 0
                                            ? `${shift.numberOfPackage} Packages`
                                            : null
                                    }
                                    img={`${d.profileImage}`}
                                    size="20px"
                                />
                            ))}
                        </Spacer>
                    )}

                    {((!shift.isRescuer && shift.priorShiftType === "Trainer") || shift.priorShiftType === "Duty") && (
                        <Spacer top={1} style={{ display: "flex" }}>
                            {shift?.paired?.map((d, k) => (
                                <Driver
                                    key={k}
                                    name={d.driverName}
                                    jobTitle={
                                        shift.isRescuer && shift.numberOfPackage > 0
                                            ? `${shift.numberOfPackage} Packages`
                                            : null
                                    }
                                    img={`${d.profileImage}`}
                                    size="20px"
                                />
                            ))}
                        </Spacer>
                    )}

                    {!shift.isRescuer && shift.priorShiftType !== "Trainer" && (
                        <Spacer top={1} style={{ display: "flex" }}>
                            {!extraData.relatedShift && <Driver loading={true} />}
                            {extraData.relatedShift && (
                                <>
                                    {extraData.relatedShift.rescuers.map((d, k) => (
                                        <Driver
                                            key={k}
                                            name={`${d.driverName}`}
                                            jobTitle={
                                                shift.isRescuer && shift.numberOfPackage > 0
                                                    ? `${shift.numberOfPackage} Packages`
                                                    : null
                                            }
                                            img={`${d.profileImage}`}
                                            size="20px" 
                                        />
                                    ))}
                                </>
                            )}
                        </Spacer>
                    )}
                </Spacer>
            ) : undefined}
            <Spacer top={5}>
                <ThirdTitle style={{ fontSize: Theme.font.small.size }}>Packages</ThirdTitle>
                <Table>
                    <TableHeader
                        style={{
                            display: "flex",
                            alignItems: "flex-end",
                        }}
                        compact={true}
                        headers={[
                            {
                                style: {
                                    flexDirection: "column",
                                    alignItems: "baseline",
                                },
                                width: "80px",
                                label: (
                                    <>
                                        <small>Route</small>
                                    </>
                                ),
                            },
                            {
                                style: {
                                    flexDirection: "column",
                                    alignItems: "baseline",
                                },
                                width: "80px",
                                label: (
                                    <>
                                        <Icon.Marker size="20px" color={Theme.colors.info.text} />
                                        <small>Stops</small>
                                    </>
                                ),
                            },
                            {
                                style: {
                                    flexDirection: "column",
                                    alignItems: "baseline",
                                },
                                width: "80px",
                                label: (
                                    <>
                                        <Spacer top={1} />
                                        <Icon.Package size="16px" color={Theme.colors.info.text} />
                                        <small>Packages</small>
                                    </>
                                ),
                            },
                            // {
                            //     style: {
                            //         flexDirection: "column",
                            //         alignItems: "baseline",
                            //     },
                            //     width: "72px",
                            //     label: (
                            //         <>
                            //             <Spacer top={1} />
                            //             <Icon.PackageTimes size="16px" color={Theme.colors.info.text} />
                            //             <small>Missing</small>
                            //         </>
                            //     ),
                            // },
                            // {
                            //     style: {
                            //         flexDirection: "column",
                            //         alignItems: "baseline",
                            //     },
                            //     width: "72px",
                            //     label: (
                            //         <>
                            //             <Spacer top={1} />
                            //             <Icon.PackageBack size="16px" color={Theme.colors.info.text} />
                            //             <small>Returned</small>
                            //         </>
                            //     ),
                            // },
                        ]}
                    />
                    {extraData.routeCodeAssignments ? (
                        extraData.routeCodeAssignments.map((a) => (
                            <li key={a.id}>
                                <TableData compact={true} width="80px">
                                    {a.routeCode.code}
                                </TableData>
                                <TableData compact={true} width="80px">
                                    {a.completedStops || "–"}/{a.totalStops || "–"}
                                </TableData>
                                <TableData compact={true} width="80px">
                                    {a.deliveredPackages || "–"}/{(shift.isRescuer ? shift.numberOfPackage : a.totalPackages) || "–"}
                                </TableData>
                                {/*<TableData compact={true} width="72px">*/}
                                {/*    {a.missingPackages || "–"}*/}
                                {/*</TableData>*/}
                                {/*<TableData compact={true} width="72px">*/}
                                {/*    {a.returnedPackages || "–"}*/}
                                {/*</TableData>*/}
                            </li>
                        ))
                    ) : (
                        <TableRow style={{ justifyContent: "center" }}>
                            <TableData compact={true} width="auto">
                                <Loading style={{ height: 20 }} />
                            </TableData>
                        </TableRow>
                    )}
                </Table>
            </Spacer>
            <Spacer top={3} style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ position: "relative" }}>
                    <ThirdTitle style={{ fontSize: Theme.font.small.size }}>Routes</ThirdTitle>
                    {![8,9].includes(shift.category) && shift.isRescuer === false && canModify && canDeleteModify ? (
                        <DriverRouteController
                            pageLoadOut={false}
                            driver={{
                                ...shift,
                                id: shift.routeId,
                                name: shift.driver ? shift.driver.name : "Driver",
                                viewType: viewType,
                            }}
                            onOpen={() => {
                                preventClose.current = true;
                                //prepareRoutes()
                            }}
                            onHide={() => {
                                preventClose.current = false;
                                // canceler.current();
                            }}
                            addChange={actions.onRouteChange}
                        />
                    ) : (
                        <Text>{shift.routeCode.join(", ") || "–"}</Text>
                    )}
                </div>
                <div style={{ position: "relative" }}>
                    <ThirdTitle style={{ fontSize: Theme.font.small.size }}>Mileage</ThirdTitle>
                    {![8,9].includes(shift.category) && canModify && canDeleteModify ? (
                        <MileageController
                            kkl={extraData.kickoffLog}
                            rts={extraData.returnToStation}
                            date={shift.dateCreated}
                        />
                    ) : (
                        <Text>-</Text>
                    )}
                </div>

                {shift.isOpenShift == 0 && (
                    <div>
                        <ThirdTitle style={{ fontSize: Theme.font.small.size }}>Timeline</ThirdTitle>
                        <Icon.TimeLine
                            color={canDeleteModify ? Theme.colors.info.border : Theme.colors.info.shadow}
                            size="20px"
                            onClick={() => {
                                { canDeleteModify && Timeline(shift.id, shift.driverId); }
                            }}
                        />
                    </div>
                )}
            </Spacer>
            <Spacer top={3} style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ position: "relative" }}>
                    <ThirdTitle style={{ fontSize: Theme.font.small.size }}>Vehicle</ThirdTitle>
                    <Text>
                        {shift.isOpenShift ? (
                            extraData.vehicle ? (
                                extraData.vehicle.vehicleId
                            ) : (
                                "–"
                            )
                        ) : shift.category != 9 &&canModify && canDeleteModify ? (
                            <DriverVehicleController
                                pageLoadOut={false}
                                driver={{
                                    ...shift,
                                    vehicle: extraData.vehicle
                                        ? { id: extraData.vehicle.vehicleId, unit: extraData.vehicle.vehicleId }
                                        : [],
                                    name: shift.driver.name,
                                    station: shift.stationId,
                                    shiftType: shift.category,
                                }}
                                addChange={actions.onVehicleChange}
                            />
                        ) : (
                            <Text>{extraData.vehicle ? extraData.vehicle.vehicleId : "–"}</Text>
                        )}
                    </Text>
                </div>
                <div style={{ position: "relative" }}>
                    <ThirdTitle style={{ fontSize: Theme.font.small.size }}>Devices</ThirdTitle>
                    {shift.isOpenShift ? (
                        extraData.devices.length > 0 ? (
                            extraData.devices.map((device, key) => {
                                return (
                                    <Link href={`tel:${device.number}`} key={key} style={{ display: "block" }}>
                                        {device.name}
                                    </Link>
                                );
                            })
                        ) : (
                            "-"
                        )
                    ) : shift.category != 9 && canModify && canDeleteModify ? (
                        <DriverDeviceController
                            pageLoadOut={false}
                            driver={{
                                ...shift,
                                devices: extraData.devices ? extraData.devices : [],
                                name: shift.driver.name,
                                shiftType: { category: shift.category },
                            }}
                            addChange={actions.onVehicleChange}
                            breakLine={true}
                        />
                    ) : (
                        <Text>{extraData.devices && extraData.devices.length ? extraData.devices[0].name : "–"}</Text>
                    )}
                </div>
                <div>
                    <ThirdTitle style={{ fontSize: Theme.font.small.size }}>Version</ThirdTitle>
                    <Text>{typeof shift.mobileVersionUsed === "string" ? shift.mobileVersionUsed : "–"}</Text>
                </div>
            </Spacer>
            {footerActions.length > 0 && canModify && canDeleteModify && <Spacer top={2} />}
            {![8,9].includes(shift.category) && canModify && canDeleteModify &&
                footerActions.map((action, k) => (
                    <Spacer inline top={2} right={2} key={k}>
                        {action}
                    </Spacer>
                ))}
            {(shift.isNew || shift.hasAlert) && (
                <Spacer top={5}>
                    <Alert.Info
                        size="small"
                        useIcon={true}
                        title={"Shift actions will be available after publishing"}
                    />
                </Spacer>
            )}
        </ShiftPopupStyle>
    );
};

const DispatcherNote = ({ shift, editShift, editDeletedShiftNote, actions }) => {
    const saveNote = (e) => {
        const value = e.target.value;
        if (value !== shift.newNote) {
            if (shift.deleted == true) {
                editDeletedShiftNote({ id: shift.routeId, newNote: value }).then(
                    (resolve) => {
                        actions.onEditShiftNote(shift, {
                            ...shift,
                            newNote: value,
                        });
                    },
                    (error) => {
                        //alert({})
                    }
                )
            } else {
                editShift({ id: shift.routeId, updateRecord: { newNote: value } }).then(
                    (resolve) => {
                        actions.onEditShiftNote(shift, {
                            ...shift,
                            newNote: value,
                        });
                    },
                    (error) => {
                        //alert({})
                    }
                );
            }
        }
    };

    return (
        <div style={{ position: "relative" }}>
            <Input
                as={"textarea"}
                label={`Dispatcher's Shift Note A`}
                defaultValue={shift.newNote}
                style={{ width: "340px", minHeight: 50, marginTop: 4, fontSize: 12, padding: 2 }}
                onBlur={saveNote}
            />
        </div>
    );
};

export default ShiftBlockPopup;
